import { Controller } from '@hotwired/stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
    static values = {
        searchPlaceholder: { type: String, default: 'Search' },
        searchText: { type: String, default: 'No Results' },
    }

    slimSelect = null;

    connect() {
        this.slimSelect = new SlimSelect({
            select: this.element,
            settings: {
                searchPlaceholder: this.searchPlaceholderValue,
                searchText: this.searchTextValue,
                searchHighlight: true,
            }
        })
    }

    disconnect() {
        this.slimSelect?.destroy();
    }
}
