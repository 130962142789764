import { Controller } from '@hotwired/stimulus'
import GLightbox from 'glightbox'

export default class extends Controller {
    static values = {
        cookie: Number,
        block: String,
        embed: String,
    }

    open() {
        if (!this.hasBlockValue || !this.hasEmbedValue || !this.hasCookieValue) {
            return;
        }

        const lightbox = GLightbox({
            autoplayVideos: true,
            autofocusVideos: true,
            touchNavigation: false,
            width: '1280px',
            height: '80vh',
            elements: [
                {
                    href: this.blockValue,
                    type: 'external',
                }
            ]
        });

        console.debug(
            lightbox,
            this.hasBlockValue,
            this.hasEmbedValue,
        );

        lightbox.on('open', () => {
            const iframe = lightbox.getActiveSlide().querySelector('iframe');

            if (iframe) {
                iframe.setAttribute('data-ccb-id', this.cookieValue);
                iframe.setAttribute('data-src', this.embedValue);
                iframe.setAttribute('allow', 'autoplay; encrypted-media; picture-in-picture; fullscreen');
            }

            lightbox.nextButton.style.display = 'none';
            lightbox.prevButton.style.display = 'none';
        });

        lightbox.open();
    }
}
