import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['days', 'hours', 'minutes', 'seconds'];
    static values = {
        target: Number,
        reload: Boolean
    }

    interval = null;
    target = null;

    connect() {
        this.target = new Date(this.targetValue).getTime();

        // Never reload if we are past the target date
        if (this.target - (new Date()).getTime() < 0) {
            this.reloadValue = false;
        }
        
        this._tick();
        this.interval = setInterval(this._tick.bind(this), 1000);
    }

    disconnect() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    _tick() {
        const now = new Date().getTime();
        const distance = this.target - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.daysTarget.innerText = days;
        this.hoursTarget.innerText = hours;
        this.minutesTarget.innerText = minutes;
        this.secondsTarget.innerText = seconds;

        if (distance <= 100) {
            clearInterval(this.interval);
            this.interval = null;

            this.daysTarget.innerText = '0';
            this.hoursTarget.innerText = '0';
            this.minutesTarget.innerText = '0';
            this.secondsTarget.innerText = '0';

            if (this.reloadValue) {
                window.location.reload();
            } 
        }
    }
}
